/* eslint-disable import/no-cycle */
import axios from 'axios'
import humps from 'humps'
import store from '@/store'
import { getAccessToken } from '@/utils/auth'

class HttpRequest {
  constructor (url = process.env.VUE_APP_API_URL || 'https://crmdemoapi.tubtim.in.th') {
    this.axiosInstance = axios.create({
      baseURL: url,
      timeout: 300000,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })

    this.axiosInstance.interceptors.request.use(async (config) => {
      store.dispatch('activity/increaseLoading')
      const isLoginFromCXONE = store.getters['User/isLoginFromCXONE']
      if (isLoginFromCXONE) {
        const tokenExpired = store.getters['User/tokenExpired']
        if (tokenExpired) {
          const timeNow = Math.floor(new Date().getTime() / 1000)
          const refreshToken = store.getters['User/refreshToken']
          if ((timeNow >= tokenExpired)) {
            try {
              const { data } = await axios.post(`${url}/auth/cxone/token`, { refreshToken })
              const expired = Math.floor(new Date().getTime() / 1000) + 2400
              store.dispatch('User/setRefreshToken', { token: data?.data?.refresh_token })
              store.dispatch('User/setTokenExpired', { expired })
              store.dispatch('User/setCXOneToken', { token: data?.data?.access_token })
            } catch (error) {
              store.dispatch('ticket/RESET_TICKET')
              store.dispatch('User/resetUser')
              console.error(error)
            }
          }
        }
      }

      return {
        ...config,
        data: config.data ? humps.decamelizeKeys(config.data) : config.data,
        params: config.params ? humps.decamelizeKeys(config.params) : config.params,
        headers: {
          Authorization: `Bearer ${ getAccessToken()}`
        }
      }
    }, (error) => {
      store.dispatch('activity/decreaseLoading')
      return Promise.reject(error)
    })

    this.axiosInstance.interceptors.response.use(
      (response) => {
        store.dispatch('activity/decreaseLoading')
        return humps.camelizeKeys(response.data)
      },
      (error) => {
        store.dispatch('activity/decreaseLoading')
        const newError = new Error(error)
        if (error?.response) {
          return Promise.reject(error.response.data)
        }
        const errorFail = {
          code: 503,
          message: newError.message
        }
        throw errorFail
      }
    )
  }

  setHeader ({ key, value }) {
    if (key && value) {
      this.axiosInstance.defaults.headers.common[key] = value
    }
  }

  get (endpoint, data, config) {
    return this.axiosInstance.get(endpoint, { params: data, ...config })
  }

  post (endpoint, data, config) {
    return this.axiosInstance.post(endpoint, data, config)
  }

  put (endpoint, data, config) {
    return this.axiosInstance.put(endpoint, data, config)
  }

  patch (endpoint, data, config) {
    return this.axiosInstance.patch(endpoint, data, config)
  }

  delete (endpoint, data, config) {
    return this.axiosInstance.delete(endpoint, { params: data, ...config })
  }
}

export default HttpRequest
