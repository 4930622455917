<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <Loading v-if="progressLoading > 0" />
    <component
      :is="layout"
      v-if="isLoaded">
      <router-view />
    </component>
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContentTicket from '@/components/ToastificationContentTicket.vue'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
// import TicketProvider from '@/resources/TicketProvider'
// import returnDataTicket from '@/utils/data-ticket'
import Loading from '@/components/Loading.vue'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import AuthProvider from '@/resources/AuthProvider'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SocketioService from './services/socketio.service'

// const TicketService = new TicketProvider()

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const AuthService = new AuthProvider()
export default {
  components: {
    Loading,
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull

  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  provide () {
    // use function syntax so that we can access `this`
    return {
      profileData: this.profile
    }
  },
  data () {
    return {
      isLoaded: true
    }
  },
  computed: {
    layout () {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType () {
      return this.$store.state.appConfig.layout.type
    },
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profile: 'User/user',
      progressLoading: 'activity/progressLoading'
    })
  },
  beforeCreate () {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup () {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      position: "top-left",
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: true,
      icon: false,
      timeout: 3600000,
      transition: 'Vue-Toastification__fade'
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, (val) => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses
    }
  },
  async mounted () {
    this.isLoaded = false
    this.observeMax()
    // await this.signInCXOne('thanate@boonrawd.co.th')
    const refreshIntervalId = setInterval(() => {
      console.log('SOCKET INTERVAL WORKING...')
      if (this.profile?.id) {
        this.connectSocket(this.profile?.id)
        console.log('SETUP SOCKET: ', this.profile?.id)
        clearInterval(refreshIntervalId)
      }
    }, 3000)
    this.isLoaded = true
  },
  methods: {
    ...mapActions({
      setUser: 'User/setUser'
    }),
    async signInCXOne (sessionToken) {
      try {
        const { data } = await AuthService.signInCXOne(sessionToken)
        if (data?.token) {
          this.setUser({
            ...data?.user,
            token: data?.token
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Login Success',
              icon: 'EditIcon',
              variant: 'success'
            }
          })
          this.$router.go(0)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async observeMax () {
      // Find the parent window (MAX) to register for events
      console.log('Running Post Message... RegisterForClientEvents')
      const opener = window.opener || window.parent

      // Set up your subscriptions
      const subscriptionTypes = ['all']

      // Start listening for response messages
      const login = async () => {
        const clientId = 'a9496592-61de-4190-8c6c-a47c711369b2'
        window.open(`https://cxone.niceincontact.com/auth/authorize?scope=openid&client_id=${clientId}&response_type=code&redirect_uri=${process.env.VUE_APP_URL || 'https://crmdemo.tubtim.in.th'}/cxone/auth`, '_self')
      }

    //   const listenForPostMessage = (event) => {
    //     if (
    //       event.data
    // && event.data.events
    // && event.data.issuer === 'MAX'
    //     ) {
    //       console.log('data 202', event)
    //       console.log('event?.data 203', event?.data)
    //       const data = event?.data?.events
    //       if (Array.isArray(data) && data.length > 0) {
    //         let sessionToken = data[1]?.sessionToken
    //         data.forEach((item) => {
    //           if (item?.sessionToken) {
    //             sessionToken = item?.sessionToken
    //           }
    //         })
    //         console.log('sessionToken 190', sessionToken)
    //         if (sessionToken) {
    //           console.log('201 SET SESSION, ', sessionToken)
    //           this.$store.dispatch('User/setSessionId', { sessionId: sessionToken })
    //         }
    //       }
    //       const tokenExpired = this.$store.getters['User/tokenExpired']
    //       const timeNow = Math.floor(new Date().getTime() / 1000)
    //       if ((timeNow >= tokenExpired) || !tokenExpired) {
    //         login(event?.data?.events)
    //       }
    //     }
    //   }

      // Add the listener for MAX client events.
      // window.addEventListener('message', listenForPostMessage)

      // Send the registration message to MAX
      opener.postMessage(
        {
          issuer: 'Tubtim',
          messageType: 'RegisterForClientEvents',
          subscriptionTypes
        },
        '*'
      )
      console.log('End Post Message...')
    },
    async connectSocket (profileId) {
      const pfid = profileId
      SocketioService.setupSocketConnection(profileId)
      SocketioService.socketOn('connect', () => {
        console.log('SOCKET CONNECT')
      })
      SocketioService.socketOn('disconnect', () => {
        console.log('SOCKET disconnect')
        SocketioService.setupSocketConnection(pfid)
      })
      SocketioService.socketOn(`agent-${profileId}`, async (payload) => {
        // const { data } = await TicketService.createInitial(this.profile.id)
        // // const ticket = returnDataTicket(data)
        if (payload?.success) {
          store.dispatch('ticket/ADD_TICKET', {
            ...payload?.ticket
            // ani: payload?.customerProfile?.ani || '',
            // customerProfileId: payload?.customerProfile?.id || '',
            // customerName: payload?.customerProfile?.name || ''
          })
          this.$toast({
            component: ToastificationContentTicket,
            listeners: {
              click: () => {
                this.$router.push('/ticket/add')
              }
            },
            props: {
              title: payload?.ani || payload?.transactionId || '',
              closeOnClick: true,
              timeout: 3600000,
              icon: payload?.type === 'non-voice' ? 'MessageSquareIcon' : 'PhoneIncomingIcon',
              variant: 'success',
              text: payload?.skillName || payload?.contactChannel || '',
              hideClose: false,
              customerName: payload?.ticket?.customerName,
              contactName: payload?.ticket?.contactName,
              isHaveContact: payload?.isHaveContact
            }
          })
        }
      })
    },
    retry (func, retries, delay) {
      let retriesTime = retries
      return new Promise((resolve, reject) => {
        const attempt = () => {
          func()
            .then(resolve)
            .catch((error) => {
              if (retriesTime > 0) {
                setTimeout(() => {
                  retriesTime--
                  attempt()
                }, delay)
              } else {
                reject(error)
              }
            })
        }
        attempt()
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

div, label {
  color: black !important;
}

</style>
